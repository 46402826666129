
var addContainerReplacement = (containerClass) => {
  $("." + containerClass).on("ajax:success", (event) => {
    var  xhr;
    [, , xhr] = event.detail;
    $("." + containerClass).html(xhr.responseText);
  }).on("ajax:error", () => {
    $("." + containerClass).html("<p>Try again, fill out all the fields.</p>");
  });
};

$(document).ready(() => {
  addContainerReplacement("comments_container");
  addContainerReplacement("timesheets_container");
});
