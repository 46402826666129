/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '../modules/ckeditor_loader';
import "jquery";
import Rails from "@rails/ujs";
import "bootstrap/dist/js/bootstrap";
import 'owl.carousel';
import '../src/javascripts/ppb.js';
import '../src/javascripts/main.js';

import 'waypoints/lib/jquery.waypoints.js';
import 'flexslider/2.6.2/jquery.flexslider';

import 'sticky-kit/dist/sticky-kit.js';
import '../src/javascripts/remote_form.js';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/all';

import './stylesheets.scss';
// import './images.js';
Rails.start();
